/*
For phase 1 of the project, do not allow users to access the new landing site.
Redirect them to the client portion only.

This code-level restriction is not ideal, because there is a temporary wait
time for the client portion to execute before the redirect occurs. It exists
just as a fallback.

There is a Front Door level redirect that exists, which is the primary method,
as it ensures the redirect takes place immediately.

This middleware will be removed in phase 2, when we release the main landing site.
*/
export default defineNuxtRouteMiddleware((to) => {
  if (process.client && to.path === '/') {
    const domain = window.location.hostname
    if (['client.transglobalinsurance.ca'].includes(domain)) {
      window.location.replace('/client')
    }
  }
})
